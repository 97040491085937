<app-navbar></app-navbar>

<main>
    <section class="home-banner">
        <div id="content">
            <h1>{{language.integration.main_heading}}</h1>
            <p>
                {{language.integration.sub_heading}}
            </p>
        </div>
    </section>

    <section class="globe">
        <img class="clickup" src="../../assets/pics/globe/Clickup.png" alt="clickup">
        <img class="zoho" src="../../assets/pics/globe/Zoho.png" alt="zoho">
        <img class="paypal" src="../../assets/pics/globe/Paypal.png" alt="Paypal">
        <img class="jira" src="../../assets/pics/globe/Jira.png" alt="Jira">
        <img class="whatsapp" src="../../assets/pics/globe/whatsapp.png" alt="whatsapp">
        <img class="salesforce" src="../../assets/pics/globe/Salesforce-1.png" alt="Salesforce-1">
        <img class="hubspot" src="../../assets/pics/globe/Hubspot.png" alt="Hubspot">

        <img class="globe-img" src="../../assets/pics/globe/globe.png" alt="globe">

        <img class="square" src="../../assets/pics/globe/Square.png" alt="Square">
        <img class="stripe" src="../../assets/pics/globe/Stripe.png" alt="Stripe">
        <img class="clover" src="../../assets/pics/globe/Clover.png" alt="Clover">
        <img class="toast" src="../../assets/pics/globe/Toast Table.png" alt="Toast Table">
        <img class="resi" src="../../assets/pics/globe/Resi.png" alt="Resi">
        <img class="otter" src="../../assets/pics/globe/Otter.png" alt="Otter">
        <img class="gmail" src="../../assets/pics/globe/Gmail.png" alt="Gmail">
        <img class="mailchimp" src="../../assets/pics/globe/Mailchimp.png" alt="Mailchimp">
    </section>
    
    <section class="globe-mob">
        <img class="globe-img" src="../../assets/pics/globe/Frame 2.jpg" alt="globe">
    </section>

    <section class="pointers">
        <div id="content">
            <p>
                {{language.integration.pointer_heading}}
            </p>
            <div class="points">
                <div *ngFor="let p of language.integration.points">
                    <h4>{{p.title}} <span>{{p.description}}</span></h4>
                </div>
            </div>
        </div>
    </section>

    <section class="learn-more">
        <div class="content">
            <h4>{{language.demo.heading}}</h4>
            <p>{{language.demo.sub_heading}}</p>
            <a href="{{language.links.request}}">
                <button>{{language.navbar.request_a_demo}}</button>
            </a>
        </div>
        <div class="image">
            <img src="../../assets/pics/4.png" alt="pic"/>
        </div>
    </section>
</main>

<app-footer></app-footer>