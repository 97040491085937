<section class="connected">
    <div class="social" *ngFor="let social of language.socials">
        <div class="title">
            <img src="{{social.icon}}" alt="{{social.icon}}">
            <h4>{{social.name}}</h4>
            <p>{{social.para}}</p>
        </div>
        <div style="background-color: #EDF3FF; color: #7B61FF;" class="status" *ngIf="!social.added"
            (click)="openPopup(social.name)">
            <span class="material-symbols-outlined">
                add_circle
            </span>
            {{language.connect}}
        </div>
        <div style="background-color: #EDFCE4; color: #0F993E;" class="status" *ngIf="social.added"
            (click)="openPopup(social.disconnect_name)">
            <span class="material-symbols-outlined">
                done
            </span>
            Connected
        </div>
    </div>
</section>

<div class="popup-overlay" *ngIf="isPopupOpen">
    <div class="popup-content">
        <div class="title">
            <h2>{{language.add}} {{isPopupOpen.name}}</h2>
            <button (click)="closePopup()"><span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>

        <div class="content" *ngIf="isPopupOpen == 'Website'">
            <form [formGroup]="websiteBusinessDetails">
                <h4>{{language.website_details}}</h4>
                <div>
                    <label>{{language.business_website_url}}<span>*</span></label>
                    <input placeholder="{{language.business_website_url}}" type="text" formControlName="businessWebsite"
                        required>
                </div>
                <button type="submit" [disabled]="!websiteBusinessDetails.valid" (click)="connectWebsite()">
                    {{language.save}}
                </button>
            </form>
        </div>
        <div class="content" *ngIf="isPopupOpen == 'Google Places'">
            <form [formGroup]="googleBusinessDetails" *ngIf="!googlePlaces">
                <h4>{{language.business_details}}</h4>
                <div>
                    <label>{{language.business_phone}}<span>*</span></label>
                    <input placeholder="{{language.business_phone_placeholder}}" type="tel"
                        formControlName="businessPhone" required>
                </div>
                <button type="submit" [disabled]="!googleBusinessDetails.valid" (click)="googleLookUp()">
                    {{language.look_me_up}}
                </button>
            </form>

            <div class="places" *ngIf="googlePlaces">
                <h4 *ngIf="googlePlaces.length && googlePlaces.length <= 1">{{googlePlaces.length}} {{language.listing}}
                </h4>
                <h4 *ngIf="googlePlaces.length && googlePlaces.length > 1">{{googlePlaces.length}} {{language.listings}}
                </h4>
                <a class="place-div" *ngFor="let place of googlePlaces; let i = index"
                    (click)="selectLocation(place, i)"
                    [style.border]="selectedImageIndex === i ? '2px solid #7B61FF' : 'none'">
                    <img src="{{place.icon}}" alt="{{place.icon}}">
                    <div>
                        <h5>{{place.name}}</h5>
                        <p>{{place.formatted_address}}</p>
                    </div>
                </a>
                <button (click)="finalizeLocation()">{{language.save}}</button>
            </div>
        </div>
        <div class="content" *ngIf="isPopupOpen == 'Website Disconnect'">
            <form>
                <h4>{{language.website_details}}</h4>
                <div>
                    <label>{{language.business_website_url}}<span>*</span></label>
                    <input name="businessWebsite" [(ngModel)]="businessDetails.website_url" disabled>
                </div>
                <button type="submit" (click)="disconnectWebsite()">
                    {{language.disconnect}}
                </button>
            </form>
        </div>
        <div class="content" *ngIf="isPopupOpen == 'Google Place Disconnect'">
            <form>
                <h4>{{language.business_details}}</h4>
                <div class=" places">
                    <a class="place-div">
                        <div>
                            <h5>{{businessDetails.business_name}}</h5>
                            <p>{{businessDetails.business_address}}</p>
                            <p>{{businessDetails.business_phone}}</p>
                        </div>
                    </a>
                </div>
                <button type="submit" (click)="disconnectGoogle()">
                    {{language.disconnect}}
                </button>
            </form>
        </div>
    </div>
</div>