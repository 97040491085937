<section>
    <footer>
        <div class="inner">
            <a routerLink="{{language.links.home}}">
                <img alt="new_logo_final" src="../../assets/pics/new_logo_final.png" />
            </a>
            <!-- <div> -->
            <!-- <a href="/contact">Contact Us!</a> -->
            <!-- <a>Privacy Policy</a> -->
            <!-- <a>Terms of Service</a> -->
            <!-- </div> -->
        </div>
        <div class="inner">
            <h3>Jump to it</h3>
            <div>
                <a href="{{language.links.contact}}">Contact Us!</a>
                <a href="{{language.links.pricing}}">Pricing</a>
                <a href="{{language.links.about}}">About Us</a>
                <a href="{{language.links.privacy}}">Privacy Policy</a>
                <!-- <a href="/about">Careers</a> -->
                <!-- <a>Blogs</a> -->
            </div>
        </div>
        <!-- <div class="inner">
            <h3>Follow us on</h3>
            <div>
                <a><img src="../../assets/pics/fb.png" /></a>
                <a><img src="../../assets/pics/instagram.png" /></a>
                <a><img src="../../assets/pics/linkedin.png" /></a>
            </div>
        </div> -->
        <div class="inner">
            <h3>{{basictrans.get_latest}}</h3>
            <form [formGroup]="userDetails">
                <input (input)="onInput($event)" type="email" placeholder="{{basictrans.email_place_holder}}" required
                    formControlName="email" />
                <button *ngIf="!success" type="submit" [disabled]="!userDetails.valid"
                    (click)="submit()">{{basictrans.sign_up}}</button>
                <button *ngIf="success" class="submited">{{basictrans.submitted}}</button>
            </form>
        </div>
    </footer>
</section>