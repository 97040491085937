<main>
    <section>
        <img class="bengium_floater" src="../../assets/pics/newfigma/semi-circle-floater.png" alt="semi-circle-floater"/>
        <img class="triangle_floater" src="../../assets/pics/newfigma/flat-circle-floater.png" alt="flat-circle-floater"/>

        <div class="selection">
            <a routerLink="{{language.sign_in_url}}">
                <span class="material-symbols-outlined">
                    arrow_back_ios
                </span>
            </a>
            <img class="login_lady" src="../../assets/pics/newfigma/signup_guy.png" alt="signup_guy"/>
            <div class="characterdiv">
                <img src="../../assets/pics/new_logo_final.png" alt="new_logo_final"/>
                <div class="title">
                    <h3>{{language.sign_up}}</h3>
                    <p>{{language.sign_up_instruction}}</p>
                </div>
                <form [formGroup]="userDetails">
                    <div>
                        <label>{{language.email_address}}<span>*</span></label>
                        <input type="email" formControlName="email" required
                            placeholder="{{language.email_place_holder}}">
                    </div>
                    <div class="password-div">
                        <label for="">{{language.password}}<span>*</span></label>
                        <input type="{{ passwordVisible ? 'text' : 'password' }}"
                            placeholder="{{language.password_place_holder}}" required formControlName="password">
                        <i class="material-symbols-outlined" (click)="togglePasswordVisibility()">
                            {{ passwordVisible ? 'visibility_off' : 'visibility' }}
                        </i>
                        <span class="span-muted-text">- {{language.password_8_character}}<br />-
                            {{language.password_description}} </span>
                    </div>
                    <div>
                        <label for="">{{language.re_enter_password}}<span>*</span></label>
                        <div>
                            <input type="{{ passwordVisible2 ? 'text' : 'password' }}"
                                placeholder="{{language.re_enter_password}}" required formControlName="repassword">
                            <i style="top: 53%" class="material-symbols-outlined" (click)="togglePasswordVisibility2()">
                                {{ passwordVisible2 ? 'visibility_off' : 'visibility' }}
                            </i>
                        </div>
                        <span>{{errMsg}}</span>
                    </div>
                    <button type="submit" [disabled]="!userDetails.valid" (click)="signUp()">
                        {{language.submit}}
                    </button>
                    <app-spinner [isLoading]="isLoading"></app-spinner>
                </form>

                <p>{{language.already_have_acc}} <a routerLink="{{language.sign_in_url}}">{{language.sign_in}}</a></p>
            </div>
        </div>
        <div class="endliner">
            <p>{{language.copyright}}</p>
        </div>
    </section>
</main>