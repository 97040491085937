<section class="template1">
    <div class="heading">
        <div class="logoDesktop">
            <img routerLink="{{language.links.home}}" src="../../assets/pics/new_logo_final.png" alt="new_logo_final"/>
        </div>
        <nav class="navbar navbar-inverse navbar-static-top" role="navigation">
            <div class="navbar-header">

                <button *ngIf="close" type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1" (click)="change()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>

                <button *ngIf="!close" type="button" class="navbar-toggle collapsed" style="padding: 6px 10px;"
                    data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" (click)="change()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </button>
            </div>

            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav">
                    <li *ngFor="let link of language.navbar.links">
                        <a *ngIf="link.type === 'internal'" queryParamsHandling="merge" routerLinkActive="active"
                            class="nav-link" [routerLink]="link.link">{{link.text}}</a>

                        <a *ngIf="link.type === 'external' && !link.options.length" [href]="link.link">{{link.text}}</a>

                        <a *ngIf="link.type === 'external' && link.options.length" class="nav-link dropdown-toggle"
                            id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {{link.text}}
                        </a>
                        <!-- <div *ngIf="link.type === 'external' && link.options.length && option" class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <div class="dropdown-values" *ngFor="let option of link.options">
                                <a target="_blank" [href]="option.link" class="dropdown-item">{{option.name}}</a>
                            </div>
                        </div> -->
                    </li>
                    <a class="start" href="{{language.links.get_started}}"><button>
                            {{language.navbar.get_started}}
                        </button></a>

                    <a href="{{language.links.request}}"><button>
                            {{language.navbar.request_a_demo}}
                        </button></a>
                </ul>
            </div>
        </nav>
    </div>
</section>