<app-navbar></app-navbar>

<main>
    <section class="home-banner">

        <div id="content">
            <h1>{{language.pricing.heading}}</h1>
            <p>
                {{language.pricing.sub_heading}}
            </p>
            <a target="_blank" href="{{language.links.stripe}}">
                <button>
                    {{language.pricing.btn}}
                </button>
            </a>
        </div>
    </section>

    <section class="faq-container">
        <h3>{{language.faq.heading}}</h3>

        <div class="faq" *ngFor="let faq of language.faq.content; let i = index">
            <div class="faq-question" (click)="toggleFaq(i)">
                {{ faq.ques }}
            </div>
            <img src="https://s3.ap-south-1.amazonaws.com/cdn.ghc.health/90d3e168-11f8-4c0f-994a-1c9a14f9aeab.png"
                class="arrow-icon" [class.collapse]="faq.open" [class.expand]="!faq.open" (click)="toggleFaq(i)" alt="arrow-icon"/>
            <img src="https://s3.ap-south-1.amazonaws.com/cdn.ghc.health/539ed68e-6dca-41d0-bdb6-0dd810e0435f.jpg"
                class="arrow-icon" [class.collapse]="!faq.open" [class.expand]="faq.open" (click)="toggleFaq(i)" alt="arrow-icon"/>
            <div class="faq-answer" [class.collapse]="!faq.open" [class.expand]="faq.open" (click)="toggleFaq(i)">
                {{ faq.ans }}
            </div>
        </div>
    </section>

    <section class="learn-more">
        <div class="image">
            <img src="../../assets/pics/table.png" alt="table"/>
        </div>
        <div class="content">
            <h4>{{language.ready_to_elevate.heading}}</h4>
            <a href="{{language.links.request}}">
                <button>{{language.navbar.request_a_demo}}</button>
            </a>
        </div>
    </section>
</main>

<app-footer></app-footer>